// Import our CSS
import 'aos/src/sass/aos.scss';
import styles from '../scss/app.scss';
import 'vue-select/dist/vue-select.css'

// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'

//Import vue components
import Vuex from 'vuex'
import AOS from 'aos';
import { mixin as clickaway } from 'vue-clickaway'
// import { Swiper as SwiperClass, Pagination, Navigation, EffectFade } from 'swiper';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueYoutube from 'vue-youtube'
import 'promise-polyfill/src/polyfill';
import VueNumber from 'vue-number-animation';
import SkyReveal from 'sky-reveal';

// App main
const main = async () => {
	// Async load the vue module
	const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

	//Enable Vue agile
	// Vue.use(VueAgile)
	Vue.use(VueAwesomeSwiper);
	Vue.use(SkyReveal);
	// SwiperClass.use([Pagination, Navigation, EffectFade]);

	//Enable Vuex store
	Vue.use(Vuex)

	//Enable Vue youtube
	Vue.use(VueYoutube)
	Vue.use(VueNumber)

	//Vuex config and mutations
	const store = new Vuex.Store({
		state: {
			isMobileMenuOpen: false,
			isLanguageSelectorOpen: false,
			scrollPosition: 0,
			purchasableId: 0,
			isMounted: false,
			showSearch: false,
			showVideo: false,
			youtubeId: '',
			cartTotalQty: 0,
			overlayOpen: false,
			showPrivacyPopup: false
		},
		mutations: {
			closeMobileMenu(state) {
				state.isMobileMenuOpen = false
			},
			openMobileMenu(state, value) {
				state.isMobileMenuOpen = value
			},
			adjustScrollPosition(state, position) {
				state.scrollPosition = position
			},
			setLanguageSelectorState(state, value) {
				state.isLanguageSelectorOpen = value
			},
			setShowSearchState(state, value) {
				state.showSearch = value
			},
			setOverlayState(state, value) {
				state.overlayOpen = value
			},
			setshowPrivacyPopup(state, value) {
				state.showPrivacyPopup = value
			},
		}
	});

	// Create our vue instance
	// @ts-ignore
	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],
		store,
		components: {
			'Accordion': () => import(/* webpackChunkName: "Accordion" */ './components/Accordion.vue'),
			'Search': () => import(/* webpackChunkName: "Search" */ './components/Search.vue'),
			'LineChart': () => import(/* webpackChunkName: "LineChart" */ './components/LineChart.vue'),
			'ColumnChart': () => import(/* webpackChunkName: "ColumnChart" */ './components/ColumnChart.vue'),
			'PieChart': () => import(/* webpackChunkName: "PieChart" */ './components/PieChart.vue'),
			'Newsletter': () => import(/* webpackChunkName: "Newsletter" */ './components/Newsletter.vue'),
			'ContactList': () => import(/* webpackChunkName: "ContactList" */ './components/ContactList.vue'),
			'EventSignup': () => import(/* webpackChunkName: "EventSignup" */ './components/EventSignup.vue'),
			'EntriesList': () => import(/* webpackChunkName: "EntriesList" */ './components/EntriesList.vue'),
			'ReadMore': () => import(/* webpackChunkName: "ReadMore" */ './components/ReadMore.vue'),
			'PrivacyPopup': () => import(/* webpackChunkName: "PrivacyPopup" */ './components/PrivacyPopup.vue'),
			'YoutubeVideo': () => import(/* webpackChunkName: "YoutubeVideo" */ './components/YoutubeVideo.vue'),
			'Jobcounter': () => import('./components/Jobcounter.vue'),
			'Educations': () => import(/* webpackChunkName: "Educations" */ './components/Educations.vue'),
		},
		mixins: [clickaway],
		data() {
			return {
				pageUrl: window.location.href,
				megahover: false,
				swiperOptionTop: {
					loop: true,
					effect: 'fade',
					loopedSlides: 1, // looped slides should be the same
					spaceBetween: 10,
					pagination: {
						clickable: true,
						el: '.swiper-pagination'
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				swiperOptionCaption: {
					loop: true,
					loopedSlides: 1, // looped slides should be the same
					spaceBetween: 10,
					effect: 'fade',
					fadeEffect: {
						crossFade: true
					},
					centeredSlides: true,
					slidesPerView: 'auto',
					slideToClickedSlide: false,
					allowTouchMove: false
				}
			}
		},
		props: {
			cart: Array
		},
		methods: {
			handleScroll: function (event) {
				if (store.state.overlayOpen) {
					return;
				}

				const app = document.getElementById('app')
				let scrollPosY = window.pageYOffset | document.body.scrollTop;

				if (app) {
					if (scrollPosY > 35) {

						if (!app.classList.contains('scrolled')) {
							app.classList.add('scrolled')
						}

					} else if (scrollPosY <= 35) {
						app.classList.remove('scrolled')
					}

					store.commit('adjustScrollPosition', scrollPosY)

					//Close language selector
					switch (store.state.isLanguageSelectorOpen) {
						case true:
							store.commit('setLanguageSelectorState', false)
							break

						default:
							store.commit('setLanguageSelectorState', false)
							break
					}
				}
			},
			toggleLanguageSelectorState: function () {
				switch (store.state.isLanguageSelectorOpen) {
					case true:
						store.commit('setLanguageSelectorState', false)
						break

					case false:
						store.commit('setLanguageSelectorState', true)
						break

					default:
						store.commit('setLanguageSelectorState', false)
						break
				}
			},
			closeLanguageSelector: function () {
				if (store.state.isLanguageSelectorOpen) {
					store.commit('setLanguageSelectorState', false)
				}
			},
			slideTo(parentRef, ref) {
				let parentSlide = this.$refs[parentRef].$swiper
				let slider = this.$refs[ref].$swiper;
				slider.slideTo(parentSlide.activeIndex)
			},
			toggleSearch() {
				store.commit('setShowSearchState', !store.state.showSearch)
				this.toggleScroll(store.state.showSearch)
			},
			toggleScroll(active) {
				let html = document.getElementById('html');
				html.classList.toggle('no-scroll');

				if (active) {
					html.style.top = `-${store.state.scrollPosition}px`
					store.commit('setOverlayState', true)
				} else {
					window.scrollTo(0, store.state.scrollPosition);
					store.commit('setOverlayState', false)
				}
			},
			openContactList() {
				this.$refs.contactlist.slideOut()
			},
			copyPageUrl() {
				let dummy = document.createElement('input'),
					text = window.location.href;

				document.body.appendChild(dummy);
				dummy.value = text;
				dummy.select();
				document.execCommand('copy');
				document.body.removeChild(dummy);
			},
			togglePrivacyPopup() {
				store.commit('setshowPrivacyPopup', !store.state.showPrivacyPopup)
			},
			toggleMobileMenu() {
				store.commit('openMobileMenu', !store.state.isMobileMenuOpen)
				this.toggleScroll(store.state.showSearch)
			},
			toggleMobileElement(e) {
				e.target.parentNode.classList.toggle('open')
			}
		},
		computed: {
			isMobileMenuOpen() {
				return store.state.isMobileMenuOpen
			},
			isLanguageSelectorOpen() {
				return store.state.isLanguageSelectorOpen
			},
			showSearch() {
				return store.state.showSearch
			},
			showPrivacyPopup() {
				return store.state.showPrivacyPopup
			},

			isMounted() {
				return store.state.isMounted
			}
		},
		created() {
			AOS.init({
				easing: 'ease-in-out',
				delay: 0,
				once: true,
				startEvent: 'load',
				anchorPlacement: 'top-bottom'
			})

			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
		beforeMount: function () {
		},
		mounted() {
			this.$nextTick(function () {
				store.state.isMounted = true;
			})
		},
	});

	return vm;
};

// Execute async function
main().then((vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
